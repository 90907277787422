<template>
	<div class="my_supply">
		<el-tabs class="tabs" type="card" v-model="screen_type" @tab-click="handleClick">
			<!-- <el-tab-pane label="供应列表" name="2"></el-tab-pane> -->
			<el-tab-pane label="供求列表" name="1"></el-tab-pane>
		</el-tabs>
		<div class="actions">
			<div class="add" @click="$router.push('/supply_demand')"><div class="el-icon-plus"></div> 发布供求</div>
		</div>
		<div class="table_tits">
			<div>货物信息</div>
			<div>求购量</div>
			<div>截止时间</div>
			<div>企业信息</div>
			<div>手机号码</div>
			<div>操作</div>
		</div>
		<div v-loading="loading">
			<div class="supply_list" v-if="supply_demand_list.length">
				<div class="item" v-for="(item,index) in supply_demand_list" @click="$router.push('/supplyDemand/detail-'+item.id)" :key="index">
					<div class="cargo_info">
						<div class="type-text" :class="item.type == 1 ? 'gong-style' : 'qiu-style'">
							{{item.type == 1 ? '求' : '供'}}
						</div>
						<div class="cargo_info2">
							<div class="name">{{item.goods_name}}</div>
							<div class="time">发布日期：{{$util.timeStampTurnTime(item.create_time).split(' ')[0]}}</div>
						</div>
					</div>
					<div class="number">
						<span>{{item.num}}</span>{{item.unit || '件'}}
					</div>
					<div class="end_time">
						<div class="time">{{$util.timeStampTurnTime(item.deadline_time).split(' ')[0]}}</div>
						<!-- <div class="day" v-if="remainingTime(item.deadline_time,'css')">剩余 <span>{{remainingTime(item.deadline_time,'')}}</span> 天</div> -->
					</div>
					<div class="company">{{item.corporate_name}}</div>
					<div class="phone">{{item.phone}}</div>
					<div class="btns" v-if="screen_type == 1">
						<div class="btn" @click="deleteSupplyDemand(item.id)">删除</div>
						<!-- <div class="btn">查看详情</div> -->
					</div>
				</div>
			</div>
			<div v-if="!supply_demand_list.length" class="empty_data">暂无数据</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {supplyDemandPageList,deleteSupplyDemand} from '@/api/organization'
	export default {
		data() {
			return {
				screen_type:'1',
				loading: true,
				total: 0,
				currentPage: 1,
				pageSize: 6,
				supply_demand_list:[],
			}
		},
		created() {
			this.getList();
		},
		methods: {
			deleteSupplyDemand(id){
				this.$confirm("您确定要删除吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					deleteSupplyDemand({
						id:id
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'删除成功'})
							this.getList()
						}else{
							this.$message.error(res.message);
						}
					})
				})
			},
			remainingTime(time,mode){
				var current_time = parseInt(new Date().getTime()/1000);
				if(time > current_time ){
					if(mode=='css'){
						return true;
					}
					console.log(this.$util.countDown(time-current_time),48)
					return this.$util.countDown(time-current_time).d
				}else{
					if(mode=='css'){
						return false;
					}
				}
			},
			handleClick(tab, event) {
				this.screen_type = tab.name;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getList();
			},
			getList(){
				supplyDemandPageList({
					page:this.currentPage,
					page_size:this.pageSize,
					type:0,
					is_my: 1
				})
				.then(res=>{
					if(res.code>=0){
						this.supply_demand_list = res.data.list;
						this.total = res.data.count;
						this.loading = false;
					}
				})
			},
			handleCurrentPageChange(page) {
				this.currentPage = page
				this.getList();
			},
			handlePageSizeChange(size) {
				this.pageSize = size
				this.getList();
			},
		},
	}
</script>

<style lang="scss">
	.my_supply{
		min-height: 730px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 18px 25px 18px 18px;
		.empty_data{
			text-align: center;
			margin-top: 20px;
		}
		.tabs{
			margin-bottom: 17px;
			.el-tabs__header{
				margin-bottom: 0;
			}
			.el-tabs__nav-wrap::after{
				height: 1px;
				background-color: #E0E0E0;
			}
			// .el-tabs__active-bar{
			// 	width: 126px !important;
			// 	top: 0 !important;
			// 	left: -34px !important;
			// }
			.el-tabs__item{
				box-sizing: border-box;
				padding: 0 !important;
				height: auto;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 8px 34px !important;
				background-color: #F7F8FA !important;
				// border: 1px solid #E0E0E0;
				border-bottom: none !important;
				// &.is-top:last-child{
				// 	border-left: none;
				// }
				&.is-active{
					line-height: 21px !important;
					color: #00A98F;
					background-color: #fff !important;
				}
			}
		}
		.actions{
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			.add{
				cursor: pointer;
				color: #fff;
				font-size: 13px;
				line-height: 18px;
				padding: 6px 13px;
				border-radius: 4px;
				display: flex;
				align-items: center;
				background-color: #00A98F;
				.el-icon-plus{
					font-size: 12px;
					margin-right: 3px;
					line-height: 12px;
				}
			}
		}
		.table_tits{
			border: 1px solid #E0E0E0;
			background-color: #F7F8FA;
			padding: 12px 0 12px 24px;
			display: flex;
			align-items: center;
			div{
				color: #333;
				font-size: 14px;
				line-height: 20px;
				&:first-child{
					width: 23%;
				}
				&:nth-child(2){
					width: 10%;
				}
				&:nth-child(3){
					width: 15%;
				}
				&:nth-child(4){
					width: 24%;
				}
				&:nth-child(5){
					width: 16%;
				}
				&:nth-child(6){
					flex: 1;
				}
			}
		}
		.supply_list{
			border: 1px solid #E0E0E0;
			border-top: none;
			.item{
				cursor: pointer;
				display: flex;
				align-items: flex-start;
				padding: 21px 0 15px 24px;
				border-bottom: 1px solid #E0E0E0;
				&:last-child{
					border-bottom: none;
				}
				>div{
					&:first-child{
						width: 23%;
					}
					&:nth-child(2){
						width: 10%;
					}
					&:nth-child(3){
						width: 15%;
					}
					&:nth-child(4){
						width: 24%;
					}
					&:nth-child(5){
						width: 16%;
					}
					&:nth-child(6){
						flex: 1;
					}
				}
				.cargo_info{
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-right: 10px;
					.cargo_info2{
						max-width: 150px;
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						box-sizing: border-box;
						padding-right: 10px;
					}
					.name{
						color: #0DAD94;
						font-size: 15px;
						line-height: 20px;
						margin-bottom: 6px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.time{
						color: #666;
						font-size: 13px;
						line-height: 18px;
					}
				}
				.number{
					color: #666;
					font-size: 13px;
					line-height: 18px;
					span{
						color: #DB363C !important;
						font-size: 13px;
						line-height: 18px;
					}
				}
				.end_time{
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					.time{
						color: #666;
						font-size: 13px;
						line-height: 18px;
					}
					.day{
						color: #666;
						font-size: 13px;
						line-height: 18px;
						margin-top: 6px;
						span{
							color: #DB363C;
							font-size: 13px;
							line-height: 18px;
						}
					}
				}
				.company{
					box-sizing: border-box;
					color: #222;
					font-size: 13px;
					line-height: 18px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					padding-right: 10px;
				}
				.phone{
					color: #666;
					font-size: 13px;
					line-height: 18px;
				}
				.btns{
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					.btn{
						color: #105CFB;
						font-size: 14px;
						line-height: 20px;
						margin-bottom: 12px;
						cursor: pointer;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.type-text{
		text-align: center;
		line-height: 30px;
		color: #fff;
		background-color: #0DAD94;
		border-radius: 10px;
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
	.gong-style{
		background-color: #ff5500;
	}
</style>